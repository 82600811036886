import React, { useEffect, useState } from "react";
import {
  Box,
  MenuItem,
  FormControl,
  InputLabel,
  Select,
  Radio,
  FormControlLabel,
  RadioGroup,
  FormLabel,
  Badge,
  Typography,
  useMediaQuery,
  Divider,
  TextField,
  Button,
} from "@mui/material";
import PatientCard from "../../components/PatientCard/PatientCard";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import moment from "moment/moment";
import { authAxios } from "../../NetworkRequest";
import dayjs from "dayjs";
import MailIcon from "@mui/icons-material/Mail";
import MarkEmailReadIcon from "@mui/icons-material/MarkEmailRead";
import UnsubscribeIcon from "@mui/icons-material/Unsubscribe";
import EventBusyIcon from "@mui/icons-material/EventBusy";
import HourglassEmptyIcon from "@mui/icons-material/HourglassEmpty";
import SearchIcon from "@mui/icons-material/Search";
import HourglassFullIcon from "@mui/icons-material/HourglassFull";

const CurrentDayReservations = ({
  reservations,
  setReservations,
  dayReservation,
  setDayReservation,
  durations,
  filterDuration,
  setFilterDuration,
  confirm_msg,
  queue_msg,
  come_msg,
}) => {
  //const [filterDuration, setFilterDuration] = useState(null)
  const [currentReservations, setCurrentReservations] = useState(reservations);
  const [currentDay, setCurrentDay] = useState(
    moment(new Date(dayReservation)).format("dddd")
  );
  const matches = useMediaQuery("(max-width:480px)");
  const matcheRow = useMediaQuery("(max-width:890px)");
  const [toShow, setToShow] = useState([]);
  const [showType, setShowType] = useState("ALL");
  const [searchPhone, setSearchPhone] = useState("");
  const [isSearching, setIsSearching] = useState(false);

  const groupedReservations = (reservations_arr)=>reservations_arr.reduce((acc, reservation) => {
      const { durationId } = reservation;
      if (!acc[durationId]) {
          acc[durationId] = [];
      }
      acc[durationId].push(reservation);
      return acc;
  }, {});

  useEffect(() => {
    let tmp = reservations;
    switch (showType) {
      case "ALL":
        tmp = reservations
        break;
      case "CANCEL":
        tmp = tmp.filter((r) => r.cancel);
        break;
      case "DONE":
        tmp = tmp.filter((r) => r.done);
        break;
      case "DIDNTCOME":
        tmp = tmp.filter((r) => r.didntCome);
        break;
      case "COME":
        tmp = tmp.filter((r) => !r.done && !r.cancel && !r.didntCome && r.come)
        break;
      case "NOTCOME":
        tmp = tmp.filter((r) => !r.done && !r.cancel && !r.didntCome && !r.come)
        break;
      default:
        tmp = reservations
        break;
    }
    const acc = groupedReservations(tmp)
    console.log(acc)
    setToShow(acc)
  }, [reservations, filterDuration]);

  const onChangeCome = async (id) => {
    try {
      let response = await authAxios.post("/reservation/come", { r_id: id });
    } catch (e) {
      console.log(e);
    }
  };


  const CheckupNumber = ()=>{
    const durations = Object.keys(toShow)
    let n = 0
    durations.forEach((duration)=>{
      n += toShow[duration].filter((r) => r.visitType === "كشف").length
    })
    return n
  }

  const ConsultationNumber = ()=>{
    const durations = Object.keys(toShow)
    let n = 0
    durations.forEach((duration)=>{
      n += toShow[duration].filter((r) => r.visitType === "استشارة").length
    })
    return n
  }

  const onChangeUrgent = async (id) => {
    try {
      let response = await authAxios.post("/reservation/urgent", { r_id: id });
    } catch (e) {
      console.log(e);
    }
  };

  const onChangeDuration = ({ target }) => {
    setFilterDuration(target.value === "clear" ? "" : target.value);
  };

  const onChangeVeryUrgent = async (id) => {
    try {
      let response = await authAxios.post("/reservation/veryurgent", {
        r_id: id,
      });
    } catch (e) {
      console.log(e);
    }
  };

  const onDidntCome = async (id) => {
    try {
      let response = await authAxios.post("/reservation/didntcome", {
        r_id: id,
      });
      let tmp = [...reservations];
      let foundIndex = tmp.findIndex((x) => x.id === id);
      tmp[foundIndex].didntCome = !reservations[foundIndex].didntCome;
      setReservations(tmp);
    } catch (e) {
      console.log(e);
    }
  };
  const onConfirm = async (id) => {
    try {
      let response = await authAxios.post("/reservation/confirm", { r_id: id });
      let tmp = reservations.filter((e) => e.id !== id);
      setReservations(tmp);
    } catch (e) {
      console.log(e);
    }
  };
  const onDone = async (id) => {
    try {
      let response = await authAxios.post("/reservation/done", { r_id: id });
    } catch (e) {
      console.log(e);
    }
  };
  const onCancelConfirm = async (id) => {
    try {
      let tmp = [...reservations];
      let response = await authAxios.post("/reservation/cancel", { r_id: id });
      updateReservations();
    } catch (e) {
      console.log(e);
    }
  };
  const onReject = (index) => {
    let tmp = [...reservations];
    tmp[index].cancel = true;
    setReservations(tmp);
  };
  const onCancelReject = (index) => {
    let tmp = [...reservations];
    tmp[index].cancel = false;
    setReservations(tmp);
  };

  const onChangeDay = async (value) => {
    try {
      let q = `date=${value}`;
      if (filterDuration !== null) q += `&durationId=${filterDuration}`;
      let response = await authAxios.get(`/reservation/all?${q}`);
      setDayReservation(dayjs(new Date(value)));
      setCurrentDay(moment(new Date(value)).format("dddd"));

      let grouped = groupedReservations(response.data.reservations)

        let all_reservations = []

        Object.keys(grouped).forEach((key) => {
          let wait = grouped[key]
            .filter((r) => !r.done && !r.cancel && !r.didntCome)
            .map((r, i) => ({ ...r, queueNumber: i + 1 }));

          let rest = response.data.reservations.filter(
            (r) => (r.done || r.cancel || r.didntCome) && r.durationId === key
          );
          all_reservations = [...all_reservations, ...wait, ...rest]
        });

        setReservations(all_reservations);

    } catch (e) {
      console.log(e);
    }
  };
  const onMakeItLast = async (id) => {
    try {
      let response = await authAxios.post("/reservation/last", { r_id: id });
      updateReservations();
    } catch (e) {
      console.log(e);
    }
  };
  const updateReservations = async () => {
    try {
      let results = await authAxios.get(
        `/reservation/all?date=${dayReservation}`
      );

      let grouped = groupedReservations(results.data.reservations)

      let all_reservations = []

      Object.keys(grouped).forEach((key) => {
        let wait = grouped[key]
          .filter((r) => !r.done && !r.cancel && !r.didntCome)
          .map((r, i) => ({ ...r, queueNumber: i + 1 }));

        let rest = results.data.reservations.filter(
          (r) => (r.done || r.cancel || r.didntCome) && r.durationId === key
        );
        all_reservations = [...all_reservations, ...wait, ...rest]
      });

      setReservations(all_reservations);

    } catch (e) {
      console.log(e);
    }
  };
  const onCancel = (index) => {
    let tmp = [...reservations];
    tmp[index].canceled = !reservations[index].canceled;
    setReservations(tmp);
  };

  const makeItWaiting = async (id) => {
    try {
      let response = await authAxios.post("/reservation/makewaiting", {
        r_id: id,
      });
    } catch (e) {
      console.log(e);
    }
  };

  const onChangeSearchPhone = ({ target }) => {
    setSearchPhone(target.value);
    let values = reservations.filter((r) => r.mobile.includes(target.value))
    
    setToShow(groupedReservations(values));
    if (target.value === "") {
      let tmp = reservations;
      switch (showType) {
        case "ALL":
          setToShow(groupedReservations(reservations));
          break;
        case "CANCEL":
          console.log("inside cancel");
          setToShow(groupedReservations(tmp.filter((r) => r.cancel)));
          break;
        case "DONE":
          setToShow(groupedReservations(tmp.filter((r) => r.done)));
          break;
        case "DIDNTCOME":
          setToShow(groupedReservations(tmp.filter((r) => r.didntCome)));
          break;
        case "COME":
          setToShow(
            groupedReservations(tmp.filter((r) => !r.done && !r.cancel && !r.didntCome && r.come))
          );
          break;
        case "NOTCOME":
          setToShow(
            groupedReservations(tmp.filter((r) => !r.done && !r.cancel && !r.didntCome && !r.come))
          );
          break;
        default:
          setToShow(groupedReservations(reservations));
          break;
      }
    }
    if (target.value.trim() === "") updateReservations();
  };

  const onClickSearchPhone = async () => {
    try {
      if (searchPhone.trim() !== "") {
        let results = await authAxios.get(`/reservation/mobile/${searchPhone}`);
        setReservations(results.data.reservations);
      }
    } catch (e) {
      console.log(e);
    }
  };


  const onChangeShowType = ({ target }) => {
    let value = target.value;
    let tmp = reservations;
    setShowType(value);
    switch (value) {
      case "ALL":
        setToShow(groupedReservations(reservations));
        break;
      case "CANCEL":
        setToShow(groupedReservations(tmp.filter((r) => r.cancel)));
        break;
      case "DONE":
        setToShow(groupedReservations(tmp.filter((r) => r.done)));
        break;
      case "DIDNTCOME":
        setToShow(groupedReservations(tmp.filter((r) => r.didntCome)));
        break;
      case "COME":
        setToShow(
          groupedReservations(tmp.filter((r) => !r.done && !r.cancel && !r.didntCome && r.come))
        );
        break;
      case "NOTCOME":
        setToShow(
          groupedReservations(tmp.filter((r) => !r.done && !r.cancel && !r.didntCome && !r.come))
        );
        break;
      default:
        setToShow(groupedReservations(reservations));
        break;
    }
  };

  return (
    <>
      <h3 style={{ textAlign: "center", marginTop: 20 }}>{currentDay}</h3>
      <div style={!matcheRow ? {display: 'flex', flexDirection: 'row-reverse', justifyContent: 'space-around'} : {}}>
      <Box
        sx={{
          display: "flex",
          flexDirection: "row",
          alignItems: "center",
          justifyContent: "center",
          marginBottom: 4,
        }}
      >
        <FormControl sx={{ minWidth: 160 }}>
          <InputLabel id="select-duration">الفترة</InputLabel>
          <Select
            labelId="select-duration"
            value={filterDuration}
            label="فترة"
            sx={{ minWidth: 160 }}
            onChange={onChangeDuration}
          >
            <MenuItem key="clear" value="clear">
              جميع الفترات
            </MenuItem>
            {durations &&
              durations.map((d) => (
                <MenuItem key={d.id} value={d.id}>
                  {d.title}
                </MenuItem>
              ))}
          </Select>
        </FormControl>
        <DatePicker
          //disablePast
          slotProps={{ textField: { style: { margin: 2 } } }}
          label="اختر تاريخ الحجز"
          format="YYYY - MM - DD"
          value={dayReservation}
          onChange={(value) => onChangeDay(value)}
        />
      </Box>
      <Box
        style={{ marginBottom: "30px" }}
        sx={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <Button
          variant="contained"
          startIcon={<SearchIcon />}
          sx={{ marginRight: 2, marginTop: 2 }}
          onClick={onClickSearchPhone}
          disabled={isSearching}
        >
          بحث
        </Button>
        <TextField
          label="رقم الهاتف"
          variant="standard"
          value={searchPhone}
          type="search"
          onKeyDown={(e) => e.key === "Enter" && onClickSearchPhone()}
          onChange={onChangeSearchPhone}
          sx={{ textAlign: "center" }}
        />
        <Box sx={{ marginLeft: 2 }}>
          <Box
            sx={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <Typography>
              {CheckupNumber()}
            </Typography>
            <Typography sx={{ width: 70, textAlign: "right" }}>:كشف</Typography>
          </Box>
          <Box
            sx={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <Typography>
              {ConsultationNumber()}
            </Typography>
            <Typography sx={{ width: 70, textAlign: "right" }}>
              :استشارة
            </Typography>
          </Box>
        </Box>
      </Box>
      </div>
      <center>
        <Divider sx={{ marginTop: 1, marginBottom: 1 }} />
        <FormControl variant="filled">
          <FormLabel id="row-radio-buttons-group-label">الحالة</FormLabel>
          <RadioGroup
            row
            aria-labelledby="row-radio-buttons-group-label"
            name="row-radio-buttons-group"
            dir="rtl"
            value={showType}
            onChange={onChangeShowType}
            sx={{
              display: "flex",
              justifyItems: "center",
              alignItems: "center",
            }}
          >
            <FormControlLabel
              value="ALL"
              control={<Radio size="small" />}
              label={
                <R_Label
                  title={"الكل"}
                  Icon={<MailIcon fontSize={matches ? "11px" : undefined} />}
                  number={reservations.length}
                />
              }
              sx={{ margin: 0, padding: 0, fontSize: "5px" }}
            />
            <FormControlLabel
              value="COME"
              control={<Radio size="small" />}
              label={
                <R_Label
                  title={"موجود"}
                  Icon={
                    <HourglassFullIcon
                      fontSize={matches ? "11px" : undefined}
                    />
                  }
                  number={
                    reservations.filter(
                      (r) => !r.done && !r.cancel && !r.didntCome && r.come
                    ).length
                  }
                />
              }
              sx={{ margin: 0, padding: 0 }}
            />
            <FormControlLabel
              value="NOTCOME"
              control={<Radio size="small" />}
              label={
                <R_Label
                  title={"غير موجود"}
                  Icon={
                    <HourglassEmptyIcon
                      fontSize={matches ? "11px" : undefined}
                    />
                  }
                  number={
                    reservations.filter(
                      (r) => !r.done && !r.cancel && !r.didntCome && !r.come
                    ).length
                  }
                />
              }
              sx={{ margin: 0, padding: 0 }}
            />
            <FormControlLabel
              value="DONE"
              control={<Radio size="small" />}
              label={
                <R_Label
                  title={"تمت"}
                  Icon={
                    <MarkEmailReadIcon
                      fontSize={matches ? "11px" : undefined}
                    />
                  }
                  number={reservations.filter((r) => r.done).length}
                />
              }
              sx={{ margin: 0, padding: 0 }}
            />
            <FormControlLabel
              value="CANCEL"
              control={<Radio size="small" />}
              label={
                <R_Label
                  title={"ملغي"}
                  Icon={
                    <UnsubscribeIcon fontSize={matches ? "11px" : undefined} />
                  }
                  number={reservations.filter((r) => r.cancel).length}
                />
              }
              sx={{ margin: 0, padding: 0 }}
            />
            <FormControlLabel
              value="DIDNTCOME"
              control={<Radio size="small" />}
              label={
                <R_Label
                  title={"لم يحضر"}
                  Icon={
                    <EventBusyIcon fontSize={matches ? "11px" : undefined} />
                  }
                  number={reservations.filter((r) => r.didntCome).length}
                />
              }
              sx={{ margin: 0, padding: 0 }}
            />
          </RadioGroup>
        </FormControl>
        <Divider style={{ marginTop: 20, marginBottom: 20 }} />
          {Object.keys(toShow).map((key, i) => (
            <div key={toShow[key][0].durationId}>
              <h3 style={{textAlign: 'center', backgroundColor: 'rgba(0, 0, 0, 0.1)', color: 'rgba(0, 0, 0, 0.4)'}}>{toShow[key][0].duration}</h3>
              <div style={!matches ? {flexDirection: 'row-reverse', display: 'flex', flexWrap: 'wrap', alignItems: 'center', justifyContent: 'center'} : {}}>
              {toShow[key].map((r, index) => (
                <PatientCard
                  key={r.id}
                  index={index}
                  queueNumber={r.queueNumber-1}
                  name={r.name}
                  mobile={r.mobile}
                  visitDate={r.visitDate}
                  visitType={r.visitType}
                  number={r.number}
                  cancel={r.cancel}
                  cancelDate={r.cancelDate}
                  createdBy={r.createdBy}
                  duration={r.duration}
                  confirmed={r.confirmed}
                  done={r.done}
                  doneDate={r.doneDate}
                  id={r.id}
                  rejected={r.rejected}
                  urgent={r.urgent}
                  veryUrgent={r.veryUrgent}
                  createdAt={r.createdAt}
                  come={r.come}
                  didntCome={r.didntCome}
                  confirmedBy={r.confirmedBy}
                  rejectedBy={r.rejectedBy}
                  onChangeCome={onChangeCome}
                  onChangeUrgent={onChangeUrgent}
                  onChangeVeryUrgent={onChangeVeryUrgent}
                  waiting={r.waiting}
                  confirm_msg={confirm_msg}
                  queue_msg={queue_msg}
                  come_msg={come_msg}
                  //onCancel={onCancel}
                  makeItWaiting={makeItWaiting}
                  updateReservations={updateReservations}
                  onMakeItLast={onMakeItLast}
                  onDidntCome={onDidntCome}
                  onDone={onDone}
                  onConfirm={onConfirm}
                  onReject={onReject}
                  onCancelConfirm={onCancelConfirm}
                  onCancelReject={onCancelReject}
                  durations={durations}
                  durationId={r.durationId}
                  showWaiting={r.showWaiting}
                  notes={r.notes}
                />
              ))}
              </div>
            </div>
          ))}
      </center>
    </>
  );
};

const R_Label = ({ title, Icon, number }) => {
  const matches = useMediaQuery("(max-width:460px)");
  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        marginTop: 2,
      }}
    >
      <Badge badgeContent={number} color="secondary">
        {Icon}
      </Badge>
      <Typography style={matches ? { fontSize: 11 } : {}}>{title}</Typography>
    </Box>
  );
};

export default CurrentDayReservations;
